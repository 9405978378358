/* src/pages/DashboardPage/DashboardPage.css */
.dashboard-page {
  display: flex;
  flex-direction: column;
}

.tab-nav {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tab-nav button {
  padding: 10px 20px;
  cursor: pointer;
}

.tab-content {
  flex-grow: 1;
}
