/* src/pages/Allocations/Allocations.css */
.allocations {
    display: flex;
    flex-direction: row;
  }
  
  .portfolio-summary {
    width: 30%;
    padding: 20px;
    border-right: 1px solid #ddd;
  }
  
  .portfolio-table {
    width: 70%;
    padding: 20px;
  }
  
  .portfolio-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .portfolio-table th, .portfolio-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  