/* src/pages/Settings/Settings.css */
.settings {
    padding: 20px;
  }
  
  .settings input[type="file"] {
    display: block;
    margin-bottom: 10px;
  }
  
  .settings button {
    padding: 10px 20px;
    cursor: pointer;
  }
  